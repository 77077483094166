import React from "react";
import Armwrestle from "../src/pictures/predator/armwrestle.webp";

// import NewsletterEmail from "./NewsletterEmail";

function Support() {
  return (
    <div>
      <section>
        <div className="aboutTextContainer" style={{ marginTop: "0px" }}>
          <h1 className="supportTitle">
            Be <span className="green">Awesome</span>
          </h1>
          <div>
            <img
              src={Armwrestle}
              className="article-image"
              alt={"Player Avatar"}
              style={{ objectFit: "cover", paddingTop: "0px" }}
            />
          </div>
          <p style={{ lineHeight: " 30px" }}>
            {" "}
            Looking for a way to support us without having to sacrifice a kidney
            on the black market? Then consider{" "}
            <span className="green">buying us a beer!</span> It's the easy and
            affordable way to show your love and help keep us motivated as we
            work hard to bring you top-notch content. And best of all, it's
            <span className="green"> completely optional!</span> Or if you’re
            ready to literally wear your FilmSlobs love on your sleeve, check
            out our shop!
          </p>
        </div>
      </section>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <a
            href="https://www.buymeacoffee.com/filmslobs"
            target="_blank"
            rel="noreferrer noopener"
          >
            <button className="beerButton">🍺 Buy us a beer!</button>
          </a>
        </div>
        <div>
          <a
            href="https://filmslobs.printify.me/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <button className="shopButton">👕 Browse our shop!</button>
          </a>
        </div>
      </div>
      <div className="contactLogoContainer">
        <div className="aboutTextContainer"></div>
      </div>
    </div>
  );
}

export default Support;
